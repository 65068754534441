import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "../src/css/main.scss";
import "../src/css/bootstrap.min.css";

import Sidebar from "./Component/Sidebar";
import Header from "./Component/Header";
import Dashboard from "./Screens/Dashboard";
import SignIn from "./Screens/Signin";
import AppState from "./context/Context";
import ProductManagement from "./Screens/ProductManagement";
import EditProduct from "./Screens/EditProduct";
import Addproduct from "./Screens/Addproduct";
import BannerManagement from "./Screens/BannerManagement";


function Main() {
  const location = useLocation();
  const currPath = location.pathname;

  return (
    <div className="">
      {/* Sidebar Column */}
      <div>
        {currPath === "/SignIn" ? (
          <SignIn />
        ) : (
          <>
            <Sidebar />
            <Header />
           
          </>
        )}
      </div>

      {/* Content Column */}
      <div className="">
        <Routes>
          <Route path="/" name="SignIn" element={<Navigate to="/SignIn" />} />
          {/* <Route path="/SignIn" element={<SignIn />} /> */}
          <Route path="/DashBoard" element={<Dashboard />} />
          <Route path="/ProductManagement" element={<ProductManagement />} />
          
          <Route path="/editproduct" element={<EditProduct />} />
          <Route path="/Addproduct" element={<Addproduct />} />
          
          <Route path="/BannerManagement" element={<BannerManagement />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <AppState>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </AppState>
  );
}

export default App;
