import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import lock from "../img/lock.png";
import user from "../img/user.png";
import Logo from "../img/Logo.png";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const [showOtp, setShowOtp] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [forgotEmail, setForgotEmail] = useState(""); // Email for forgot password
  const [otpSent, setOtpSent] = useState(false); // State to show OTP input after email is sent
  const [otp, setOtp] = useState(""); // State for the OTP input
  const [newPasswordModal, setNewPasswordModal] = useState(false); // State to show the new password modal
  const [newPassword, setNewPassword] = useState(""); // State for new password
  const [confirmPassword, setConfirmPassword] = useState(""); // State for confirm password

  const navigate = useNavigate();

  const handleSignInClick = async () => {
    if (email.trim() === "" || password.trim() === "") {
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
      return;
    }

    try {
      const response = await fetch(
        "https://lunarsenterprises.com:5008/boutique/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();

      if (response.ok && data.result) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        navigate("/Dashboard", { state: { user_id: data.user_id } });
      } else {
        setShowMessage(true);
        document
          .querySelectorAll("input")
          .forEach((input) => (input.style.border = "1px solid red"));
        setTimeout(() => setShowMessage(false), 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleForgotPassword = async () => {
    if (forgotEmail.trim() === "") {
      alert("Please enter an email.");
      return;
    }

    try {
      // Make API call to send OTP
      const response = await fetch(
        "https://lunarsenterprises.com:5008/boutique/mail-send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: forgotEmail }),
        }
      );

      if (response.ok) {
        setOtpSent(true); // Show OTP input if email is successfully sent
        alert("OTP sent to your email.");
      } else {
        alert("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleSendOtp = async () => {
    if (otp.trim() === "") {
      alert("Please enter the OTP.");
      return;
    }

    try {
      // API call to verify OTP
      const response = await fetch(
        "https://lunarsenterprises.com:5008/boutique/otp-verification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: forgotEmail,
            code: otp,
          }),
        }
      );

      const data = await response.json();

      // Handle the response correctly based on your API response structure
      if (response.ok && data.result) {
        alert(data.message); // Correctly access 'message' from the response
        setShowOtp(false); // Close modal after OTP verification
        setNewPasswordModal(true); // Show new password modal
      } else {
        alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred during OTP verification. Please try again.");
    }
  };

  const handleChangePassword = async () => {
    if (newPassword.trim() === "" || confirmPassword.trim() === "") {
      alert("Please fill in both fields.");
      return;
    }
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(
        "https://lunarsenterprises.com:5008/boutique/change-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: forgotEmail,
            password: newPassword,
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data.result) {
        alert(data.message);
        setNewPasswordModal(false); // Close the new password modal
        setShow(false); // Close the forgot password modal
      } else {
        alert("Failed to change password. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="login-main container-fluid">
      <div className="signinstyle">
        <img
          src={Logo}
          className="redlogo"
          alt="Red Logo"
          height="100px"
          width="100px"
        />
        <p
          className="mb-4"
          style={{
            color: "#daba48",
            fontWeight: "bold",
            fontSize: "40px",
            textAlign: "center",
            fontFamily: "sans-serif",
          }}
        >
          Sign In
        </p>

        <div className="login-wrapper-inner">
          <div className="mt-4 input-wrapper-login">
            <div className="icondirection icon-input-login">
              <img src={user} className="Reguser-icon" alt="User Icon" />
            </div>
            <input
              type="text"
              className="form-control login-input"
              placeholder="Enter User Name"
              aria-label="Enter User Name"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mt-4 input-wrapper-login">
            <div className="icondirection icon-input-login">
              <img src={lock} className="Reguser-icon" alt="Lock Icon" />
            </div>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control login-input"
              placeholder="Enter Password"
              aria-label="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="password-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FaEye style={{ width: "24px", height: "24px" }} />
              ) : (
                <FaEyeSlash style={{ width: "24px", height: "24px" }} />
              )}
            </div>
          </div>
          <div className="text-center mt-3">
            <a
              href="#"
              className="text-decoration-none text-black colorforgot"
              onClick={() => setShow(true)}
            >
              Forgot Your Password?
            </a>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btnsign"
              style={{
                color: "#ffff",
                fontSize: "20px",
                fontFamily: "sans-serif",
              }}
              onClick={handleSignInClick}
            >
              SIGN IN
            </button>
          </div>
        </div>
      </div>

      {/* Forgot Password Modal */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-dialog-centered"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Forgot Password?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-4 input-wrapper-login">
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Email"
              aria-label="Enter Email"
              value={forgotEmail}
              onChange={(e) => setForgotEmail(e.target.value)}
            />
            {otpSent && (
              <div className="mt-3">
                <label>Enter OTP</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter OTP"
                  aria-label="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!otpSent ? (
            <Button variant="primary" onClick={handleForgotPassword}>
              Send OTP
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSendOtp}>
              Verify OTP
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* New Password Modal */}
      <Modal
        show={newPasswordModal}
        onHide={() => setNewPasswordModal(false)}
        dialogClassName="modal-dialog-centered"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Set New Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-4 input-wrapper-login">
            <label>New Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter New Password"
              aria-label="Enter New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="mt-4 input-wrapper-login">
            <label>Confirm Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Confirm New Password"
              aria-label="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChangePassword}>
            Change Password
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SignIn;
