import React, { useState } from "react";
import Layout from "../Component/Layout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Addproduct() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 415);
  const [productDetails, setProductDetails] = useState({});
  const [mainImage, setMainImage] = useState(null);
  const [subImages, setSubImages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({ ...productDetails, [name]: value });
  };

  const savedEmail = localStorage.getItem("email");

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Prevent multiple submissions
    if (isSubmitting) return;
    
    setIsSubmitting(true); // Disable button after first click

    const formData = new FormData();
    formData.append("product_name", productDetails.product_name);
    formData.append("product_description", productDetails.product_description);
    formData.append("product_price", productDetails.product_price);
    formData.append(
      "product_discount_price",
      productDetails.product_discount_price
    );
    formData.append("product_stock", productDetails.product_stock);
    formData.append("user_email", savedEmail);

    // Append main image file if selected
    if (mainImage) {
      formData.append("image", mainImage);
    }

    // Append up to 4 sub-images with correct field names
    subImages.forEach((image, index) => {
      formData.append(`image${index + 1}`, image);
    });

    try {
      const response = await fetch(
        "https://lunarsenterprises.com:5008/boutique/add-product",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.result === true) {
        toast.success("Product added successfully", {
          autoClose: 3000,
        });

        // Clear form after successful submission
        setProductDetails({
          product_name: "",
          product_description: "",
          product_price: "",
          product_discount_price: "",
          product_stock: "",
        });
        setMainImage(null); // Reset main image state
        setSubImages([]); // Reset sub-images state

        // Navigate to Dashboard after 3 seconds
        setTimeout(() => {
          navigate("/Dashboard");
        }, 3000);
      } else {
        toast.error("Error adding product: " + data.message, {});
      }
    } catch (error) {
      toast.error("Error adding product", {});
      console.error("Error adding product:", error);
    } finally {
      setIsSubmitting(false); // Re-enable button after API call completes
    }
  };

  const handleMainImageChange = (e) => {
    setMainImage(e.target.files[0]);
    toast.success("Image added successfully", {
      autoClose: 3000,
    });
  };

  const handleSubImagesChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length <= 4) {
      setSubImages(selectedFiles);
      toast.success("Sub-images added successfully", {
        autoClose: 3000,
      });
    } else {
      toast.error("Please select up to 4 sub-images.", {
        autoClose: 3000,
      });
    }
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit}>
        <div className="product-display">
          <div className="selected-product">
            <div className="product-details">
              <h5 style={{ color: "black", fontSize: 25, fontWeight: 500 }}>
                Add Product
              </h5>
              <div className="selected-product-price">
                <h6 style={{ color: "black", fontSize: 16 }}>Original Price</h6>
                <input
                  className="addinput form-control"
                  name="product_price"
                  value={productDetails?.product_price || ""}
                  onChange={handleInputChange}
                  placeholder="original price"
                />
              </div>
              <div className="selected-product-price">
                <h6 style={{ color: "black", fontSize: 16 }}>Discount Price</h6>
                <input
                  className="addinput form-control"
                  name="product_discount_price"
                  value={productDetails?.product_discount_price || ""}
                  onChange={handleInputChange}
                  placeholder="discount price"
                />
              </div>

              <h6 style={{ color: "black", fontSize: 16 }}>Product Stock</h6>
              <div>
                <input
                  className="addinput form-control"
                  name="product_stock"
                  value={productDetails?.product_stock || ""}
                  onChange={handleInputChange}
                  placeholder="product quantity"
                />
              </div>

              <div className="selected-product-details">
                <h6 style={{ color: "black", fontSize: 16 }}>Product Name</h6>
                <input
                  className="addinput form-control"
                  name="product_name"
                  value={productDetails?.product_name || ""}
                  onChange={handleInputChange}
                  placeholder="product name"
                />

                <h6 style={{ color: "black", fontSize: 16 }}>Product Image</h6>
                <input type="file" onChange={handleMainImageChange} />

                <h6 style={{ color: "black", fontSize: 16 }}>Sub Images</h6>
                <input
                  type="file"
                  multiple
                  onChange={handleSubImagesChange}
                  accept="image/*"
                />

                {/* Display selected sub-images */}
                {subImages.length > 0 && (
                  <div className="sub-images-preview">
                    <h6 style={{ color: "black", fontSize: 16 }}>
                      Selected Sub Images:
                    </h6>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {subImages.map((image, index) => (
                        <div key={index}>
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Sub Image ${index + 1}`}
                            style={{
                              width: "75px",
                              height: "75px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <h6 style={{ color: "black", fontSize: 16 }}>Description</h6>
                <input
                  className="addinputdes form-control"
                  name="product_description"
                  value={productDetails?.product_description || ""}
                  onChange={handleInputChange}
                  placeholder="description"
                />
              </div>
              <div className="add-buy-buttons">
                <button
                  type="submit"
                  disabled={isSubmitting} // Disable button while submitting
                  style={{
                    backgroundColor: isSubmitting ? "grey" : "black",
                    color: "white",
                    borderRadius: 10,
                    fontSize: 16,
                    fontWeight: 500,
                    border: "none",
                  }}
                >
                  {isSubmitting ? "ADDING PRODUCT..." : "ADD PRODUCT"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </Layout>
  );
}

export default Addproduct;
