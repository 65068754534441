import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Product from "../img/product-1.png";

function BannerManagement() {
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [loading, setLoading] = useState(true); // State to manage loading
  const [product, setProducts] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({ ...productDetails, [name]: value });
  };

  const handleMainImageChange = (e) => {
    setMainImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("banner_name", productDetails.banner_name);
    formData.append("banner_description", productDetails.banner_description);

    // Append image file if selected
    if (mainImage) {
      formData.append("image", mainImage);
    }

    try {
      const response = await fetch(
        "https://lunarsenterprises.com:5008/boutique/add-banner",
        {
          method: "POST",
          headers: {
            user_id: "1",
          },
          body: formData,
        }
      );
      const data = await response.json();
      if (data.result === true) {
        toast.success(data.message, {
          autoClose: 3000,
        });

        // Clear form after successful submission
        setProductDetails({
          banner_name: "",
          banner_description: "",
        });
        setMainImage(null); // Reset main image state

        // Navigate to Dashboard after 3 seconds
        toast.success(data.message, {
          autoClose: 3000,
        });

        fetchProducts();
      } else {
        console.error("Error adding product:", data.message);
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/list-banner",
        {},
        {
          headers: {
            user_id: "1",
          },
        }
      );

      setProducts(response.data.list);
      console.log(response.data.list, "productdatalist");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const DeleteProducts = async (bannerId) => {
    const url = "https://lunarsenterprises.com:5008/boutique/delete-banner";
    console.log(bannerId, "bannerid");
    const headers = {
      user_id: "1",
    };
    const data = {
      banner_id: bannerId,
    };

    try {
      const response = await axios.post(url, data, { headers });
      if (response.status === 200) {
        console.log(response.data.message);
        toast.success(response.data.message);
        fetchProducts(); // Refresh the product list after deletion
        return response.data;
      } else {
        console.error("Failed to delete banner:", response);
      }
    } catch (error) {
      console.error("Error deleting banner:", error);
    }
  };

  return (
    <Layout>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="product-display">
            <div className="selected-product">
              <div className="product-details">
                <h5 style={{ color: "black", fontSize: 25, fontWeight: 500 }}>
                  Add Product
                </h5>

                <div className="selected-product-details">
                  <h6 style={{ color: "black", fontSize: 16 }}>Product Name</h6>
                  <input
                    className="addinput form-control"
                    name="banner_name"
                    value={productDetails?.banner_name || ""}
                    onChange={handleInputChange}
                    placeholder="banner name"
                  />

                  <h6 style={{ color: "black", fontSize: 16 }}>
                    Product Image
                  </h6>
                  <input type="file" onChange={handleMainImageChange} />

                  <h6 style={{ color: "black", fontSize: 16 }}>Description</h6>
                  <input
                    className="addinputdes form-control"
                    name="banner_description"
                    value={productDetails?.banner_description || ""}
                    onChange={handleInputChange}
                    placeholder="description"
                  />
                </div>
                <div className="add-buy-buttons">
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: 10,
                      fontSize: 16,
                      fontWeight: 500,
                      border: "none",
                    }}
                  >
                    ADD BANNER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="container-lg products">
          <div className="text-body" style={{ marginBottom: 20 }}>
            Banner Management
          </div>
          <div className="collection-product-display">
            {loading ? (
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  marginLeft: "50%",
                }}
              >
                <div
                  className=""
                  style={{
                    border: "10px solid #f3f3f3",
                    borderTop: "10px solid #3498db",
                    borderRadius: "50%",
                    width: "100px",
                    height: "100px",
                    animation: "spin 2s linear infinite",
                  }}
                ></div>
              </div>
            ) : (
              product?.map((product, index) => (
                <div className="collection-product-card" key={product.b_id}>
                  <div className="collection-product-image-container">
                    <div
                      className="delete-container"
                      onClick={() => DeleteProducts(product.b_id)}
                    >
                      Delete
                    </div>

                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={
                        product.b_image
                          ? `https://lunarsenterprises.com:5008/${product.b_image}`
                          : Product
                      }
                      alt=""
                      className="collection-default"
                    />
                  </div>
                  <div>
                    <h4
                      style={{
                        color: "black",
                        fontSize: 13,
                        fontFamily: "sans-serif",
                      }}
                    >
                      {product.b_name}
                    </h4>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </Layout>
  );
}

export default BannerManagement;
