import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import Petcleaner from "../img/petcleaner.jpeg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Noproduct from "../img/noproduct.png";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
function ProductManagement() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // State to manage loading

  const handleEdit = (product) => {
    // Perform any necessary actions with data
    // Navigate to the edit page, for example
    navigate("/editproduct", { state: { product } });
  };
  // const  products = [
  //   {
  //       product_name:"Pet shampoo",
  //       product_price:"999"
  //   }
  // ]
  const [product, setProducts] = useState([]);

  const savedEmail = localStorage.getItem("email");
  console.log(savedEmail, "savedEmail");

  //..........ListAPi.........//

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/list-product"
      );
      setProducts(response.data.productlist);
      console.log(response.data.productlist, "productdatalist");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  //............Delete_Api...........//

  const DeleteProducts = async (productId) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/delete-product",
        {
          product_id: productId,
          mail_id: savedEmail,
        }
      );

      toast.success(response.data.message, {
        onClose: 3000,
      });
      fetchProducts();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Layout>
      <div className="container-lg products">
        <div className="text-body" style={{ marginBottom: 20 }}>
          Product Management
        </div>
        <div className="collection-product-display">
          {loading ? (
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                marginLeft: "50%",
              }}
            >
              <div
                className=""
                style={{
                  border: "10px solid #f3f3f3",
                  borderTop: "10px solid #3498db",
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  animation: "spin 2s linear infinite",
                }}
              ></div>
            </div>
          ) : (
            product?.map((product, index) => (
              <div className="collection-product-card" key={product.p_id}>
                <div className="collection-product-image-container">
                  <div
                    className="delete-container"
                    onClick={() => DeleteProducts(product.p_id)}
                  >
                    Delete
                  </div>
                  <div
                    className="edit-container"
                    onClick={() => handleEdit(product)}
                  >
                    Edit
                  </div>
                  <img
                    style={{
                      width: "300px",
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "15px",
                    }}
                    src={
                      product.p_image
                        ? `https://lunarsenterprises.com:5008/${product.p_image}`
                        : Noproduct
                    }
                    alt=""
                    className="collection-default"
                  />
                </div>
                <div>
                  <h4
                    style={{
                      color: "black",
                      fontSize: 13,
                      fontFamily: "sans-serif",
                    }}
                  >
                    {product.p_name}
                  </h4>
                </div>
                <div className="product-price-details">
                  <h4
                    style={{
                      color: "black",
                      fontSize: 15,
                      fontFamily: "sans-serif",
                    }}
                  >
                    <span>Rs.</span>
                    {product.price}
                  </h4>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
}

export default ProductManagement;
