import React, { useEffect, useRef, useState } from "react";
import Layout from "../Component/Layout";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [timeFrame, setTimeFrame] = useState("week");
  const [summary, setSummary] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState({});
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);
  const [filterStatus, setFilterStatus] = useState("pending");
  const [list, setList] = useState([]);
  const savedEmail = localStorage.getItem("email");

  //............BarGraph Api...........//
  useEffect(() => {
    BarGraphApi();
  }, []);

  const BarGraphApi = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/stock",
        { email: savedEmail }
      );
      setChartData(response.data.stock || []);
      console.log(response.data.stock, "stockchart");
    } catch (error) {
      console.log(error);
    }
  };

  const productNames = chartData?.map((product) => product.p_name);
  const productStock = chartData?.map((product) => product.p_stock);

  const data = {
    labels: productNames,
    datasets: [
      {
        label: "Stock",
        data: productStock,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Options for the bar chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const product = chartData[context.dataIndex];
            return `${product.p_name}: ${product.p_stock}`;
          },
        },
      },
      title: {
        display: true,
        text: "Product Stock Levels",
      },
    },
  };

  //...........ProductSummaryApi..........//
  const ProductSummary = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/order-summary",
        {
          email: savedEmail,
        }
      );
      console.log(response.data.data);
      setSummary(response.data.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  //..............renderPieChart...............//
  useEffect(() => {
    if (summary.length > 0) {
      renderPieChart();
    }
  }, [summary]);

  const renderPieChart = () => {
    const total = summary.reduce(
      (acc, item) => acc + item.confirmed + item.pending + item.cancelled,
      0
    );
    const data = summary?.map((item) => ({
      confirmed: (item.confirmed / total) * 100,
      pending: (item.pending / total) * 100,
      cancelled: (item.cancelled / total) * 100,
    }));

    const colors = ["#33ffec", "#33ff33", "#ff3333"];
    const canvas = chartRef.current;
    const ctx = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(centerX, centerY) - 10;
    const centerCircleRadius = radius * 0.6;
    let startAngle = 0;

    data?.forEach((item, index) => {
      const slices = [item.confirmed, item.pending, item.cancelled];
      slices?.forEach((slice, idx) => {
        const slicePercentage = slice / 100;
        const endAngle = startAngle + slicePercentage * 2 * Math.PI;

        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius, startAngle, endAngle);
        ctx.fillStyle = colors[idx];
        ctx.fill();

        startAngle = endAngle;
      });
    });

    ctx.beginPath();
    ctx.arc(centerX, centerY, centerCircleRadius, 0, 2 * Math.PI);
    ctx.fillStyle = "#fff";
    ctx.fill();

    ctx.fillStyle = "#000";
    ctx.font = "bold 24px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    // const year = summary.length > 0 ? summary[0].year : "N/A";
    // ctx.fillText(year, centerX, centerY);
  };

  //......................OrderList..............//
  useEffect(() => {
    OrderList();
  }, [filterStatus]);

  const OrderList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/order-list",
        {
          email: savedEmail,
          status: filterStatus,
        }
      );
      const orders = response.data.orderlist || [];
      setList(orders);
      console.log(orders, "orderlist");
      const statuses = orders.reduce((acc, order) => {
        acc[order.o_id] = order.o_status;
        return acc;
      }, {});
      ProductSummary();
      setOrderStatuses(statuses);
    } catch (error) {
      console.log(error);
    }
  };


  

  //............ConfirmOrder Api...........//
  const ConfirmOrderApi = async (status, order_id) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5008/boutique/filter",
        {
          status: status,
          order_id: order_id, // Include order_id in the payload
        }
      );
  
      // Update the order status in the local state
      setOrderStatuses((prevStatuses) => ({
        ...prevStatuses,
        [order_id]: status,
      }));
      console.log(response.data, "ConfirmOrder");
      OrderList()
    } catch (error) {
      console.log(error);
    }
  };
  

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
    console.log("filterStatuserr", e.target.value);
    console.log(filterStatus, "filtered");
  };

  return (
    <Layout>
      <div className="container">
        <div className="col-12">
          <div className="row">
            <div className="col-md-6 bgwhites">
              <div>
                <h1 className="text-center">Product Summary</h1>
                <div className="align-items-center justify-content-center d-flex">
                  <canvas ref={chartRef} width="200" height="250"></canvas>
                </div>
              </div>

              <div className="col-md-12 align-items-center justify-content-center d-flex">
                {summary &&
                  summary?.map((item, index) => (
                    <div key={index}>
                      <div className="graphTextside">
                        <div className="graphTextsideDotapplied"></div>
                        <h6>confirm :{item.confirmed}</h6>
                      </div>
                      <div className="graphTextside">
                        <div className="graphTextsideDotavailed"></div>
                        <h6>Pending :{item.pending} </h6>
                      </div>
                      <div className="graphTextside">
                        <div className="graphTextsideDotReject"></div>
                        <h6>cancelled : {item.cancelled}</h6>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-md-6 bgwhites">
              <div className="chart-container">
                <h1 className="text-center">Stock Availability </h1>

                <Bar data={data} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <select
          onChange={handleFilterChange}
          value={filterStatus}
          style={{
            padding: "5px",
            marginTop: "20px",
            width: "100px",
            height: "40px",
            fontSize: "16px",
            backgroundColor: "#AEEA00",
            borderRadius: "10px",
          }}
        >
          <option value="confirmed">Confirmed</option>
          <option value="pending">Pending</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </div>

      <div className="mt-5">
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "700px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Product Id</th>
                <th scope="col">Product Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Address</th>
                <th scope="col">Status</th>
                <th scope="col">Change</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((order, index) => (
                <tr className="line1px" key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{order.o_id}</td>
                  <td>{order.p_name}</td>
                  <td>{order.o_user_phone_number}</td>
                  <td>{order.o_user_address}</td>
                  <td>{orderStatuses[order.o_id] || order.o_status}</td>
                  <td>
                    <select
                      onChange={(e) =>
                        ConfirmOrderApi(e.target.value, order.o_id)
                      }
                      value={orderStatuses[order.o_id] || order.o_status}
                      style={{ border: "none", outline: "none" }}
                    >
                      <option value="confirmed">Confirmed</option>
                      <option value="pending">Pending</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
